import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: ()=> import('../views/home.vue'),
    children: [
      {
        path: 'user',
        name: 'UserManagement',
        component: () => import('../views/user.vue')
      },
      {
        path: 'goods',
        name: 'goods',
        component: () => import('../views/goods.vue')
      },
      {
        path: 'cate',
        name: 'cate',
        component: () => import('../views/cate.vue')
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('../views/order.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
